import { gql } from '@apollo/client';

export const TRANSACTION_LIST_QUERY = gql`
  query TransactionList(
    $page: Int!
    $take: Int!
    $distributorCd: String
    $source: TransactionSource
    $status: TransactionStatus
    $startDate: Date
    $endDate: Date
    $creatorCd: String
    $productCd: String
    $transactionCd: String
  ) {
    in {
      transactionList(
        startDate: $startDate
        endDate: $endDate
        page: $page
        take: $take
        distributorCd: $distributorCd
        source: $source
        status: $status
        creatorCd: $creatorCd
        productCd: $productCd
        transactionCd: $transactionCd
      ) {
        total
        data {
          id
          cd
          description
          transactionDate
          source
          status
          salesOrderId
          purchaseReceiptId
          salesOrder {
            id
            cd
          }
          distributor {
            id
            cd
            name
          }

          creator {
            id
            name
          }

          fromWarehouse {
            id
            name
          }

          toWarehouse {
            id
            name
          }
        }
      }
    }
  }
`;
