import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { store } from './utils';

export const DEFAULT_API_URL = 'https://sfa.vodkamen.vn';

const httpLink = createHttpLink({
  uri: 'https://sfa.vodkamen.vn',
});

export const authLink = setContext(async (_, { headers }) => {
  const accessToken = await store.getAccessToken();

  return {
    headers: {
      ...headers,
      authorization: `Bearer ${accessToken}`,
    },
  };
});

export const cache = new InMemoryCache({
  typePolicies: {
    McpMutationEndpoint: {
      keyFields: ['__typename'],
    },
    McpQueryEndpoint: {
      keyFields: ['__typename'],
    },
    MasterDataMutationEndpoint: {
      keyFields: ['__typename'],
    },
    MasterDataQueryEndpoint: {
      keyFields: ['__typename'],
    },
    ProgramMutationEndpoint: {
      keyFields: ['__typename'],
    },
    ProgramQueryEndpoint: {
      keyFields: ['__typename'],
    },
    OrderMutationEndpoint: {
      keyFields: ['__typename'],
    },
    OrderQueryEndpoint: {
      keyFields: ['__typename'],
    },
    InventoryQueryEndpoint: {
      keyFields: ['__typename'],
    },
    InventoryMutationEndpoint: {
      keyFields: ['__typename'],
    },
  },
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
});
