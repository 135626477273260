import { ApolloError } from '@apollo/client';
import { CButton, CCard, CCardBody } from '@coreui/react';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import * as iconsPNG from '../assets/iconPNG/index';
import { Constants } from '../constants';
import { PayloadMessage } from '../queries';
import { theme } from '../types/theme';
import { DataMessage } from './DataMessage';
import { Submitting } from './Submitting';
import { Table, TableProps } from './Table';

const customStyles = {
  rows: {
    style: {
      minHeight: '',
      paddingTop: '10px',
      paddingBottom: '10px',
    },
  },
  headCells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      fontWeight: 'bold',
      backgroundColor: theme.colors.lightBackground,
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
    },
  },
};

export const PageTable = <T extends unknown>({
  title,
  titleCreate,
  createUrl,
  headerComponent,
  listTab,
  actionComponent,
  filterComponent,
  error,
  deleting,
  deleteError,
  deleteMessage,
  onRefresh,
  onTabSelected,
  fixedHeaderScrollHeight,
  pageSizes = Constants.pageSizes,

  ...restProps
}: {
  title: string;
  titleCreate?: string;
  createUrl?: string;
  headerComponent?: React.ReactNode;
  headerTabComponent?: React.ReactNode;
  actionComponent?: React.ReactNode;
  filterComponent?: React.ReactNode;
  listTab?: { tabNum: number; title: string }[];
  error?: ApolloError;
  deleting?: boolean;
  deleteError?: ApolloError;
  deleteMessage?: PayloadMessage | null;
  fixedHeaderScrollHeight?: string;
  onRefresh?: () => void;
  onTabSelected?: (tabNum: number) => void;
} & TableProps<T>) => {
  const [isShowFilter, setIsShowFilter] = useState(false);
  // const [tabSelected, setTabSelected] = useState(0);

  return (
    <>
      <div className="d-flex justify-content-between align-items-center my-2 ">
        <span style={{ flex: 1, fontWeight: 600, fontSize: 24 }}>{title}</span>
        <div>
          {!!createUrl && (
            <Link
              style={{
                backgroundColor: theme.colors.primary,
                borderColor: theme.colors.primary,
                borderRadius: 5,
                color: theme.colors.white,
              }}
              className="btn btn-outline-primary"
              to={createUrl}
            >
              {titleCreate || 'Tạo mới'}
            </Link>
          )}
        </div>
      </div>
      <CCard className="mb-3 pb-3">
        {/* Tab header theo design khi nào dùng thì bỏ comment truyền list tab vào */}
        {/* <div
          className="border-bottom"
          style={{
            borderWidth: 1,
            paddingTop: 5,
            paddingLeft: 10,
            borderColor: theme.colors.black,
            backgroundColor: theme.colors.white,
            borderTopLeftRadius: 5,
            borderTopRightRadius: 5,
          }}
        >
          <div style={{ display: 'flex' }}>
            {(listTab || [{ tabNum: 0, title: 'Tất cả' }]).map((i) => {
              return (
                <div
                  className="btn shadow-none px-0"
                  onClick={() => {
                    setTabSelected(i.tabNum);
                  }}
                  key={i.tabNum}
                  style={{
                    alignItems: 'center',
                    alignContent: 'center',
                    justifyContent: 'center',
                    marginLeft: 5,
                    textAlign: 'center',
                  }}
                >
                  <div
                    className="row"
                    style={{
                      alignItems: 'center',
                      alignContent: 'center',
                      justifyContent: 'center',
                      paddingLeft: 15,
                      paddingRight: 15,
                    }}
                  >
                    <div className="col">
                      <text style={{ fontSize: 20, fontWeight: 400 }}>{i.title}</text>
                    </div>
                  </div>
                  {i.tabNum === tabSelected && (
                    <div
                      style={{
                        backgroundColor: theme.colors.primary,
                        height: 5,
                        borderRadius: 5,
                        marginTop: 5,
                      }}
                    />
                  )}
                </div>
              );
            })}
          </div>
        </div> */}

        <div className="d-flex justify-content-between align-items-center mx-2 mt-3">
          <div className="mx-2">
            {filterComponent && (
              <CButton
                style={{
                  backgroundColor: theme.colors.white,
                  borderColor: theme.colors.lightText,
                  borderRadius: 5,
                  alignItems: 'center',
                  alignContent: 'center',
                  alignSelf: 'center',
                }}
                onClick={() => {
                  setIsShowFilter(!isShowFilter);
                }}
              >
                <div className="d-flex justify-content-between align-items-center me-2">
                  <img
                    className="me-2"
                    src={iconsPNG.icFilter}
                    width={15}
                    sizes={'contain'}
                    alt=""
                  />
                  <text style={{ color: theme.colors.normalText }}>Lọc</text>
                </div>
              </CButton>
            )}
          </div>
          {headerComponent && (
            <div className="d-flex justify-content-between align-items-center mx-2 ">
              {headerComponent}
              {onRefresh && (
                <CButton
                  className="ms-2"
                  style={{
                    backgroundColor: theme.colors.white,
                    borderColor: theme.colors.lightText,
                    borderRadius: 5,
                    // color: theme.colors.normalText,
                    alignItems: 'center',
                    alignContent: 'center',
                    alignSelf: 'center',
                  }}
                  onClick={() => onRefresh()}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <img className="me-2 " src={iconsPNG.icRefresh} width={15} alt="" />
                    <text style={{ color: theme.colors.normalText }}>Làm mới</text>
                  </div>
                </CButton>
              )}
            </div>
          )}
        </div>
        {/* <CCardBody style={{ paddingBottom: 0, minHeight: '90vh' }}> */}
        <CCardBody style={{ paddingBottom: 0, minHeight: 'auto' }}>
          {isShowFilter && filterComponent && (
            <div
              className="p-2 border mb-4"
              style={{
                borderWidth: 1,
                borderColor: theme.colors.black,
                backgroundColor: '#FAFAFB',
                borderRadius: 6,
              }}
            >
              {filterComponent}
            </div>
          )}
          {actionComponent && (
            <div
              style={{
                borderWidth: 1,
                padding: 10,
                borderRadius: 6,
                borderColor: theme.colors.black,
                backgroundColor: '#FAFAFB',
              }}
              className="border mb-4"
            >
              {actionComponent}
            </div>
          )}
          <DataMessage error={deleteError} message={deleteMessage} dismissible={true} />
          {!!error ? (
            <DataMessage error={error} />
          ) : (
            <div
              className="border "
              style={{
                borderWidth: 1,
                borderColor: theme.colors.black,
                backgroundColor: theme.colors.white,
                borderRadius: 6,
                minHeight: '10vh',
              }}
            >
              <Table
                fixedHeaderScrollHeight={fixedHeaderScrollHeight ? fixedHeaderScrollHeight : '65vh'}
                fixedHeader={true}
                pageSizes={pageSizes}
                {...restProps}
                customStyles={customStyles}
              />
            </div>
          )}
        </CCardBody>

        {deleting && <Submitting />}
      </CCard>
    </>
  );
};
